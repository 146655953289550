import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
} from "@mui/material";
import {
  AssessmentOutlined,
  DashboardOutlined,
  EditNotificationsOutlined,
  Menu,
  PowerSettingsNewOutlined,
} from "@mui/icons-material";
import NotificationPage from "./NotificationPage";
import Apix from "../Apix";
import Swal from "sweetalert2";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: { fontFamily: "kanit" },
});
function ReportCenter() {
  const [selectedIndex, setSelectedIndex] = useState(2);
  const [accessToken, setAccessToken] = useState("");
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleStart = async () => {
    const accessTokenData = localStorage.getItem("accessToken");
    if (accessTokenData) {
      setAccessToken(accessTokenData);
      if (!(await Apix.loadCompanys(accessTokenData))) {
        localStorage.removeItem("accessToken");
        window.location.href = "/login";
      }
    } else {
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    handleStart();
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box>
          <AppBar position="static" color="primary">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Menu />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Report Center
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <Box
          sx={{ display: "flex", flexGrow: 1, overflow: "hidden", height: 0 }}
        >
          <Box maxWidth={60}>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemButton
                  disabled
                  selected={selectedIndex === 0}
                  onClick={(event) => handleListItemClick(event, 0)}
                >
                  <ListItemIcon>
                    <DashboardOutlined />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  disabled
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                >
                  <ListItemIcon>
                    <AssessmentOutlined />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                >
                  <ListItemIcon>
                    <EditNotificationsOutlined />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding divider>
                <ListItemButton
                  selected={selectedIndex === 3}
                  //onClick={(event) => handleListItemClick(event, 3)}
                  onClick={() => {
                    Swal.fire({
                      title: "ต้องการออกจากระบบ?",
                      //text:"ต้องการออกจากระบบ?",
                      icon: "question",
                      showCancelButton: true,                      
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Apix.reIndex();
                      }
                    });
                  }}
                >
                  <ListItemIcon>
                    <PowerSettingsNewOutlined />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
          <Box
            sx={{ flexGrow: 1, overflow: "hidden", backgroundColor: "#000" }}
          >
            {selectedIndex === 2 && (
              <NotificationPage accessToken={accessToken} />
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ReportCenter;
