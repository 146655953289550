import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  CssBaseline,
  Stack,
  Typography,
} from "@mui/material";
import { Carousel, Image } from "antd";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: "kanit",
  },
});

function Loading() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Box alignSelf={"center"}>
          <CircularProgress size={20} />
        </Box>
        <Typography variant="caption">Loading...</Typography>
      </Stack>
    </Box>
  );
}

function Noresult() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Alert variant="outlined" severity="error">
        <AlertTitle>ผิดพลาด</AlertTitle>
        ไม่พบไฟล์โปรดลองใหม่อีกครั้งภายหลัง
      </Alert>
    </Box>
  );
}

function Player({ vdo }) {
  const [height, setHeigh] = useState(0);
  useEffect(() => {
    const swfobject = new window.Cmsv6Player({
      domId: "cmsv6flash",
      isVodMode: true,
      width: "100%",
      height: "100%",
      lang: "en",
    });
    swfobject.setWindowNum(1);
    const resize = () => {
      setHeigh(window.innerHeight);
    };
    window.addEventListener("resize", resize);
    resize();
    if (vdo) {
      swfobject.startVodM(vdo, 0);
    }
  }, [vdo]);
  return (
    <Box
      sx={{
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <div style={{ height: height }} id="cmsv6flash"></div>
    </Box>
  );
}

function PreviewImages({ img }) {
  const [result, setResult] = useState([]);
  useEffect(() => {
    const arr = [];
    for (let i in img) {
      arr.push(img[i].downloadUrl);
    }
    setResult(arr);
  }, [img]);
  return (
    <Box
      position={'absolute'}
      top={0}
      left={0}
      right={0}
      bottom={0}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
    >
      <Carousel arrows={true} style={{ textAlign: "center" }}>
        {result.map((e, k) => (
          <div>
            <Image key={k} src={e} alt="" />
          </div>
        ))}
      </Carousel>
    </Box>
  );
}

function Playmedia() {
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);
  const [vdo, setVdo] = useState("");
  const [img, setImg] = useState("");

  const haldleStart = async (guid) => {
    const data = await fetch(`https://cctvgpsai.com/api/line/media/${guid}`);
    //const data = await fetch(`http://localhost:3003/api/line/media/${guid}`);
    if (data.status === 200) {
      const jsonData = await data.json();
      const { result, vedios, images } = jsonData;

      if (result === 0 && vedios.length > 0) {
        setErr(false);
        setVdo(vedios[0].fsl ?? "");
      } else {
        if (images.length > 0) {
          setImg(images);
        } else {
          setErr(true);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const query = params.get("guid");

    if (!query) {
      setLoading(false);
      setErr(true);
    } else {
      haldleStart(query);
    }
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {loading && <Loading />}
      {err && <Noresult />}
      {!err && !loading && vdo && <Player vdo={vdo} />}
      {!err && !loading && img && <PreviewImages img={img} />}
    </ThemeProvider>
  );
}

export default Playmedia;
