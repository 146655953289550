import {
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Result } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
const myServer = "https://dvrai.net";
function Loading() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Box alignSelf={"center"}>
          <CircularProgress size={20} />
        </Box>
        <Typography variant="caption">Loading...</Typography>
      </Stack>
    </Box>
  );
}
function Page403() {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      //extra={<Button type="primary">Back Home</Button>}
    />
  );
}
function Player({ devIdno }) {
  const [height, setHeigh] = useState(0);
  useEffect(() => {
    const swfobject = new window.Cmsv6Player({
      domId: "cmsv6flash2",
      isVodMode: false,
      width: "100%",
      height: "100%",
      lang: "en",
    });
    swfobject.setWindowNum(1);
    const resize = () => {
      setHeigh(window.innerHeight);
    };
    window.addEventListener("resize", resize);
    resize();

    const queryUserVehicle = (jsession) => {
      fetch(
        `${myServer}/StandardApiAction_queryUserVehicle.action?jsession=${jsession}&language=en`
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.result === 0) {
            finalData(res.vehicles, jsession);
          } else {
            console.log("Error");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const startPlayer = (jsession, online, chanel) => {
      if (online === 0) {
        Swal.fire({
          toast: true,
          icon: "warning",
          title: "Video device is not online",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        return;
      }
      swfobject.setWindowNum(chanel);
      swfobject.setServerInfo("dvrai.net", "6605");
      for (let i = 0; i < chanel; i++) {
        swfobject.startVideo(i, jsession, devIdno, i, 1, true);
      }
    };

    const finalData = (vehicles, jsession) => {
      const chn = vehicles.find((a) => a.dl[0].id === devIdno) ?? false;
      const chnCount = chn.chnCount ?? false;
      if (!chnCount) {
        Swal.fire({
          toast: true,
          icon: "error",
          title: "ไม่พบข้อมูลยานพาหนะ",
          //text: "ไม่พบข้อมูลยานพาหนะ",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
        return;
      }

      const chanel =
        chnCount === 1 ? 1 : chnCount <= 4 ? 4 : chnCount <= 6 ? 6 : 9;

      fetch(
        `${myServer}/StandardApiAction_getDeviceOlStatus.action?jsession=${jsession}&devIdno=${devIdno}`
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.result === 0) {
            //finalData(res.vehicles, jsession);
            startPlayer(jsession, res.onlines[0].online, chanel);
          } else {
            console.log("Error");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    (() => {
      fetch(
        `${myServer}/StandardApiAction_login.action?account=lmcc&password=lmcc`
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.result === 0) {
            queryUserVehicle(res.jsession);
          } else {
            console.log("Error");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    })();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [devIdno]);
  return (
    <Box
      sx={{
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <div style={{ height: height }} id="cmsv6flash2"></div>
    </Box>
  );
}

function RealtimeVDO() {
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(0);
  const [devIdno, setDevIdno] = useState(null); 
  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const devIdno = params.get("devIdno");
    if (!devIdno) {
      setErr(403);
      setLoading(false);
      return;
    }
    setErr(0);
    setLoading(false);
    setDevIdno(devIdno);
  }, []);
  return (
    <Fragment>
      {loading && <Loading />}
      {err === 403 && <Page403 />}
      {!err && !loading && <Player devIdno={devIdno} />}
    </Fragment>
  );
}

export default RealtimeVDO;
