import { AddAlarm } from "@mui/icons-material";
import {
  Button,
  Card,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Select,
  theme,
} from "antd";
import React, { Fragment, useRef, useState } from "react";
import Draggable from "react-draggable";
import Apix from "../Apix";
import Swal from "sweetalert2";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 17,
    },
  },
};

function NotificationPageInsert({
  accessToken,
  setDataSource,
  setLoadingData,
}) {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [value, setValue] = useState([]);
  const [group, setGroup] = useState([]);
  const [loading, setLoading] = useState(false);

  const draggleRef = useRef(null);
  let fromRef = "";

  const showModal = () => {
    const arr = [];
    for (let i in Apix.companys) {
      arr.push({
        label: Apix.companys[i].nm,
        value: Apix.companys[i].id,
      });
    }
    setGroup(arr);
    setOpen(true);
  };
  const handleOk = (e) => {
    document.getElementById("add").click();
  };
  const handleCancel = (e) => {
    fromRef.resetFields();
    setOpen(false);
  };
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const handleReloadData = async () => {
    setLoadingData(true);
    const data = await Apix.reloadNotify(accessToken);
    const { result, dataList } = data;
    if (result === 0) {
      for (let i in dataList) {
        dataList[i].key = dataList[i].id;
        dataList[i].gname = Apix.companys[dataList[i].pid].nm ?? "";
        dataList[i].pname =
          Apix.companys[Apix.companys[dataList[i].pid].pId].nm ?? "";
      }
      setDataSource(dataList);
    } else {
      if ([401, 403].includes(result)) {
        localStorage.removeItem("accessToken");
        window.location.href = "/login";
      } else {
        Swal.fire({
          toast: true,
          icon: "error",
          text: "System Error!",
          timer: 1500,
          timerProgressBar: true,
          position: "top-end",
          showConfirmButton: false,
        });
      }
    }
    setLoadingData(false);
  };

  const handleFinish = async (values) => {
    setLoading(true);
    const alarmType = [...values.eventName];
    alarmType.includes(600) && alarmType.push(601);
    alarmType.includes(602) && alarmType.push(603);
    alarmType.includes(604) && alarmType.push(605);
    alarmType.includes(618) && alarmType.push(619);
    alarmType.includes(620) && alarmType.push(621);
    alarmType.includes(622) && alarmType.push(623);
    alarmType.includes(624) && alarmType.push(625);
    alarmType.includes(626) && alarmType.push(627);
    alarmType.includes(1226) && alarmType.push(1227);
    const data = {
      notifyName: values.notiName,
      lineToken: values.token,
      alarmType: alarmType.join(","),
      pid: values.groupName,
    };

    let tmpFromRef = fromRef;
    switch (await Apix.insertNotify(data, accessToken)) {
      case 200:
        Swal.fire({
          //toast:true,
          icon: "success",
          title: "Success",
          timer: 1000,
          timerProgressBar: true,
          //position:'top-end',
          showConfirmButton: false,
        });
        setOpen(false);
        handleReloadData();
        tmpFromRef.resetFields();
        break;
      case 401:
      case 403:
        localStorage.removeItem("accessToken");
        window.location.href = "/login";
        break;
      default:
        Swal.fire({
          toast: true,
          icon: "error",
          text: "System Error!",
          timer: 1500,
          timerProgressBar: true,
          position: "top-end",
          showConfirmButton: false,
        });
        break;
    }
    setLoading(false);
  };

  const selectProps = {
    value,
    onChange: setValue,
  };
  const options = [
    { label: "เตือนการชนด้านหน้า", value: 600 },
    { label: "ขับรถออกนอกเลน", value: 602 },
    { label: "รักษาระยะห่างคันหน้า", value: 604 },
    { label: "แจ้งเตือนการหาวนอน", value: 618 },
    { label: "แจ้งเตือนการใช้โทรศัพท์", value: 620 },
    { label: "แจ้งเตือนการสูบบุหรี่", value: 622 },
    { label: "แจ้งเตือนการมองไปรอบๆ", value: 624 },
    { label: "แจ้งเตือนการบังกล้อง", value: 626 },
    { label: "แจ้งเตือนการหลับตา", value: 1226 },
  ];
  const sharedProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    options,
    placeholder: "Select Events",
    maxTagCount: "responsive",
  };

  return (
    <Fragment>
      <ConfigProvider
        theme={{
          token: { fontFamily: "kanit" },
          algorithm: theme.darkAlgorithm,
        }}
      >
        <Button size="large" onClick={showModal}>
          <AddAlarm />
        </Button>
        <Modal
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              เพิ่มการแจ้งเตือนใหม่
            </div>
          }
          open={open}
          maskClosable={false}
          width={600}
          onOk={handleOk}
          okButtonProps={{ loading: loading }}
          onCancel={handleCancel}
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              nodeRef={draggleRef}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <Card>
            <Form
              ref={(ref) => (fromRef = ref)}
              variant="filled"
              autoComplete="off"
              {...formItemLayout}
              onFinish={handleFinish}
            >
              <Form.Item
                label="ชื่อแจ้งเตือน"
                name="notiName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input allowClear placeholder="Enter Notification Name" />
              </Form.Item>

              <Form.Item
                label="โทเค่น"
                name="token"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input allowClear placeholder="Enter Token Line" />
              </Form.Item>

              <Form.Item
                label="ชื่อกลุ่ม"
                name="groupName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  //onSearch={onSearch}
                  optionFilterProp="label"
                  options={group}
                  placeholder="Select Grop Name"
                ></Select>
              </Form.Item>

              <Form.Item
                label="เหตุการณ์"
                name="eventName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select {...sharedProps} {...selectProps} />
              </Form.Item>

              <Button
                id="add"
                style={{ display: "none" }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form>
          </Card>
        </Modal>
      </ConfigProvider>
    </Fragment>
  );
}

export default NotificationPageInsert;
