class Apix {
  constructor() {
    if (process.env.NODE_ENV === "development") {
      this.url = "http://localhost:3003";
    } else {
      this.url = "https://cctvgpsai.com";
    }
    this.companys = null;
  }
  sleep = (ms) => {
    return new Promise((callback) => {
      setTimeout(callback, ms);
    });
  };
  login = async (username, password) => {
    return new Promise((callback) => {
      try {
        fetch(`${this.url}/api/line/login`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username, password }),
        })
          .then((response) => response.json())
          .then((res) => {
            callback(res);
          });
      } catch (e) {
        callback({ result: 7, message: "System exception" });
      }
    });
  };
  loadCompanys = (token) => {
    return new Promise(async (callback) => {
      try {
        const response = await fetch(`${this.url}/api/line/company`, {
          method: "post",
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          const jsonData = await response.json();
          if (jsonData["result"]) {
            callback(false);
          } else {
            this.companys = {};
            for (let i in jsonData) {
              this.companys[`${jsonData[i].id}`] = jsonData[i];
            }
          }
          callback(true);
        } else {
          callback(false);
        }
      } catch (e) {
        callback(false);
      }
    });
  };
  loadNotify = (token) => {
    return new Promise(async (callback) => {
      try {
        const response = await fetch(`${this.url}/api/line/notifications`, {
          method: "get",
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          const jsonData = await response.json();
          callback(jsonData);
        } else {
          callback(false);
        }
      } catch (e) {
        callback(false);
      }
    });
  };
  reloadNotify = (token) => {
    return new Promise(async (callback) => {
      try {
        const response = await fetch(`${this.url}/api/line/notifications`, {
          method: "get",
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          const jsonData = await response.json();
          callback(jsonData);
        } else {
          callback({ result: response.status });
        }
      } catch (e) {
        callback({ result: 404 });
      }
    });
  };
  insertNotify = (data, token) => {
    return new Promise(async (callback) => {
      try {
        const response = await fetch(`${this.url}/api/line/notifications`, {
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (response.status === 200) {
          const jsonData = await response.json();
          const { result } = jsonData;
          if (result === 0) {
            callback(200);
          } else {
            callback(501);
          }
        } else {
          callback(response.status);
        }
      } catch (e) {
        callback(404);
      }
    });
  };

  updateNotify = (data, token) => {
    return new Promise(async (callback) => {
      try {
        const response = await fetch(`${this.url}/api/line/notifications`, {
          method: "put",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (response.status === 200) {
          const js = await response.json();
          if (js.result === 0) {
            callback(200);
          } else {
            callback(500);
          }
        } else {
          callback(response.status);
        }
      } catch (e) {
        callback(404);
      }
    });
  };

  deleteNotify = (id, token) => {
    return new Promise(async (callback) => {
      try {
        const response = await fetch(
          `${this.url}/api/line/notifications/${id}`,
          {
            method: "delete",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          const js = await response.json();
          if (js.result === 0) {
            callback(200);
          } else {
            callback(500);
          }
        } else {
          callback(response.status);
        }
      } catch (e) {
        callback(404);
      }
    });
  };

  packAlarmType = (alarmType) => {
    alarmType.includes(600) && alarmType.push(601);
    alarmType.includes(602) && alarmType.push(603);
    alarmType.includes(604) && alarmType.push(605);
    alarmType.includes(618) && alarmType.push(619);
    alarmType.includes(620) && alarmType.push(621);
    alarmType.includes(622) && alarmType.push(623);
    alarmType.includes(624) && alarmType.push(625);
    alarmType.includes(626) && alarmType.push(627);
    alarmType.includes(1226) && alarmType.push(1227);
    return alarmType;
  };

  unpackAlarmType = (arm) => {
    const arrArm = arm.split(",");
    const eventName = [];
    arrArm.includes("600") && !eventName.includes(600) && eventName.push(600);
    arrArm.includes("601") && !eventName.includes(600) && eventName.push(600);
    arrArm.includes("602") && !eventName.includes(602) && eventName.push(602);
    arrArm.includes("603") && !eventName.includes(602) && eventName.push(602);
    arrArm.includes("604") && !eventName.includes(604) && eventName.push(604);
    arrArm.includes("605") && !eventName.includes(604) && eventName.push(604);
    arrArm.includes("618") && !eventName.includes(618) && eventName.push(618);
    arrArm.includes("619") && !eventName.includes(618) && eventName.push(618);
    arrArm.includes("620") && !eventName.includes(620) && eventName.push(620);
    arrArm.includes("621") && !eventName.includes(620) && eventName.push(620);
    arrArm.includes("622") && !eventName.includes(622) && eventName.push(622);
    arrArm.includes("623") && !eventName.includes(622) && eventName.push(622);
    arrArm.includes("624") && !eventName.includes(624) && eventName.push(624);
    arrArm.includes("625") && !eventName.includes(624) && eventName.push(624);
    arrArm.includes("626") && !eventName.includes(626) && eventName.push(626);
    arrArm.includes("627") && !eventName.includes(626) && eventName.push(626);
    arrArm.includes("1226") &&
      !eventName.includes(1226) &&
      eventName.push(1226);
    arrArm.includes("1227") &&
      !eventName.includes(1226) &&
      eventName.push(1226);
    return eventName;
  };

  reIndex = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };
}

module.exports = new Apix();
