import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  ConfigProvider,
  theme,
  Space,
  Button,
  Tag,
  Modal,
  Card,
  Form,
  Input,
  Select,
} from "antd";
import {
  Delete,
  Edit, 
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import Apix from "../Apix";
import NotificationPageInsert from "./NotificationPageInsert";
import Swal from "sweetalert2";
import Draggable from "react-draggable";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

function NotificationPage({ accessToken }) {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [group, setGroup] = useState([]);
  const [h, setH] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleStart = async (accessToken) => {
    while (!Apix.companys) {
      await Apix.sleep(100);
    }

    const arr = [];
    for (let i in Apix.companys) {
      arr.push({
        label: Apix.companys[i].nm,
        value: Apix.companys[i].id,
      });
    }
    setGroup(arr);

    const data = await Apix.loadNotify(accessToken);
    if (!data) {
      window.location.href = "/login";
    } else {
      const { result, dataList } = data;
      if (result !== 0) {
        console.error(data);
      } else {
        const arr = [];
        //console.log(dataList);

        for (let i in dataList) {
          dataList[i].key = dataList[i].id;
          dataList[i].gname = Apix.companys[dataList[i].pid].nm ?? "";
          dataList[i].pname =
            Apix.companys[Apix.companys[dataList[i].pid].pId].nm ?? "";
          arr.push(dataList[i]);
        }
        setDataSource(arr);
      }
    }
    setLoading(false);
  };
  const handleResetDataSource = () => {
    setDataSource([...dataSource]);
  };
  const handleEditNotifacation = (data) => {
    //setCurrentData({});
    setCurrentData(data);
    setOpen(true);
  };
  const handleUpdateDatasource = async (currentData) => {
    const tmp = dataSource.find((a) => a.id === currentData.id);
    tmp.name = currentData.notifyName;
    tmp.lineToken = currentData.lineToken;
    tmp.arm = currentData.alarmType.join(",");
    tmp.pid = currentData.pid;
    tmp.gname = Apix.companys[currentData.pid].nm ?? "";
    tmp.pname = Apix.companys[Apix.companys[currentData.pid].pId].nm ?? "";
    setDataSource([...dataSource]);
  };

  const handleDeleteNotifacation = async (a) => {
    Swal.fire({
      text: "ยืนยันการลบข้อมูล",
      icon: "question",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        switch (await Apix.deleteNotify(a.id, accessToken)) {
          case 200:
            dataSource.splice(
              dataSource.findIndex((b) => b.id === a.id),
              1
            );
            setDataSource([...dataSource]);
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              text: "Success",
              timer: 1000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
            break;
          case 401:
          case 403:
            Apix.reIndex();
            break;
          default:
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "error",
              text: "System Error",
              timer: 1000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
            setLoading(false);
            break;
        }
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setH(window.innerHeight - 280);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    accessToken && handleStart(accessToken);
  }, [accessToken]);
  return (
    <Box p={2}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography mb={2} variant="h5">
            Line Notifications
          </Typography>
          <Typography variant="subtitle1" mb={1}>
            Details
          </Typography>
        </Box>
        <Box alignSelf={"center"}>
          <Stack direction={"row"} spacing={1}>
            {/* <TextField
              size="small"
              autoComplete="off"
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            /> */}
            <NotificationPageInsert
              accessToken={accessToken}
              setDataSource={setDataSource}
              setLoadingData={setLoading}
            />
          </Stack>
        </Box>
      </Box>

      <ConfigProvider
        theme={{
          token: { fontFamily: "kanit" },
          algorithm: theme.darkAlgorithm,
        }}
      >
        <Table
          bordered
          loading={loading}
          dataSource={dataSource}
          scroll={{ x: 600, y: h }}
          columns={[
            /*{
              title: "#",
              render: (a, b, c) => c + 1,
              align: "center",
              width: 100,
            },*/
            {
              title: "ดำเนินการ",
              width: 150,
              align: "center",
              render: (a, b, c) => (
                <Space>
                  <Button
                    onClick={() => handleDeleteNotifacation(a)}
                    icon={<Delete />}
                  ></Button>
                  <Button
                    onClick={() => handleEditNotifacation(a)}
                    icon={<Edit />}
                  ></Button>
                  <SwitchNotifications
                    data={a}
                    resetData={handleResetDataSource}
                    accessToken={accessToken}
                  />
                </Space>
              ),
            },
            {
              title: "ชื่อการแจ้งเตือน",
              dataIndex: "name",
              ellipsis: true,
              ...getColumnSearchProps('name'),
              sorter: (a, b) => (a.name > b.name ? 0 : -1),
              sortDirections: ["ascend"],              
            },
            {
              title: "ชื่อกลุ่ม",
              ellipsis: true,
              dataIndex: "gname",
              sorter: (a, b) => (a.gname > b.gname ? 0 : -1),
              sortDirections: ["ascend"],
              ...getColumnSearchProps('gname'),
              /*render: (a, b, c) =>
                (Apix.companys &&
                  Apix.companys[a.pid] &&
                  Apix.companys[a.pid].nm) ??
                "",*/
            },
            {
              title: "กลุ่มหลัก",
              ellipsis: true,
              dataIndex: "pname",
              sorter: (a, b) => (a.pname > b.pname ? 0 : -1),
              sortDirections: ["ascend"],
              ...getColumnSearchProps('pname'),
              /*sorter: (a, b) =>
                Apix.companys[a.pid].pId - Apix.companys[b.pid].pId,
              render: (a, b, c) => {
                const pId =
                  Apix.companys &&
                  Apix.companys[a.pid] &&
                  Apix.companys[a.pid].pId;

                return (
                  (pId && Apix.companys[pId] && Apix.companys[pId].nm) ?? ""
                );
              },*/
            },
            //{ title: "โทเค่น" },
            {
              title: "สถานะ",
              sorter: (a, b) => a.status - b.status,
              render: (a, b, c) =>
                a.status ? (
                  <Tag color="green">เปิดการแจ้งเตือน</Tag>
                ) : (
                  <Tag color="red">ปิดการแจ้งเตือน</Tag>
                ),
              width: 170,
              align: "center",
            },
          ]}
        />
        <EditNotifacation
          open={open}
          setOpen={setOpen}
          currentData={currentData}
          group={group}
          accessToken={accessToken}
          updateData={handleUpdateDatasource}
        />
      </ConfigProvider>
    </Box>
  );
}

export default NotificationPage;

function SwitchNotifications({ data, resetData, accessToken }) {
  const [loading, setLoading] = useState(false);
  const handleSwithNoti = async (arg) => {
    setLoading(true);
    const { id, arm, name, token, pid, status } = arg;
    const obj = {
      id,
      notifyName: name,
      lineToken: token,
      alarmType: arm,
      pid,
      status: status ? 0 : 1,
    };

    switch (await Apix.updateNotify(obj, accessToken)) {
      case 200:
        arg.status = arg.status ? false : true;
        setLoading(false);
        resetData();
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Success",
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        break;
      case 401:
      case 403:
        Apix.reIndex();
        break;
      default:
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          text: "System Error",
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        setLoading(false);
        break;
    }
  };
  return (
    <Button
      onClick={() => handleSwithNoti(data)}
      loading={loading}
      icon={data.status ? <Visibility /> : <VisibilityOff />}
    />
  );
}

function EditNotifacation({
  open,
  setOpen,
  currentData,
  group,
  updateData,
  accessToken,
}) {
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(false);

  const draggleRef = useRef(null);
  const formRef = useRef(null);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 17,
      },
    },
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  const handleCancel = (e) => {
    //formRef.current?.resetFields();
    setOpen(false);
  };
  const handleOk = (e) => {
    document.getElementById("edit").click();
  };
  const handleFinish = async (values) => {
    setLoading(true);
    const alarmType = Apix.packAlarmType([...values.eventName]);
    const obj = {
      id: currentData.id,
      notifyName: values.notiName,
      lineToken: values.token,
      alarmType,
      pid: values.groupName,
      status: currentData.status ? 1 : 0,
    };

    switch (await Apix.updateNotify(obj, accessToken)) {
      case 200:
        setLoading(false);
        updateData(obj);
        setOpen(false);
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Success",
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        break;
      case 401:
      case 403:
        Apix.reIndex();
        break;
      default:
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          text: "System Error",
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        setLoading(false);
        break;
    }
    setLoading(false);
  };

  const selectProps = {
    value,
    onChange: setValue,
  };
  const options = [
    { label: "เตือนการชนด้านหน้า", value: 600 },
    { label: "ขับรถออกนอกเลน", value: 602 },
    { label: "รักษาระยะห่างคันหน้า", value: 604 },
    { label: "แจ้งเตือนการหาวนอน", value: 618 },
    { label: "แจ้งเตือนการใช้โทรศัพท์", value: 620 },
    { label: "แจ้งเตือนการสูบบุหรี่", value: 622 },
    { label: "แจ้งเตือนการมองไปรอบๆ", value: 624 },
    { label: "แจ้งเตือนการบังกล้อง", value: 626 },
    { label: "แจ้งเตือนการหลับตา", value: 1226 },
  ];

  const sharedProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    options,
    placeholder: "Select Events",
    maxTagCount: "responsive",
  };

  useEffect(() => {
    const { arm } = currentData;
    if (!arm) {
      return;
    }
    const eventName = Apix.unpackAlarmType(arm);
    formRef.current?.setFieldsValue({
      notiName: currentData.name,
      token: currentData.token,
      groupName: currentData.pid,
      eventName: eventName,
    });
  }, [currentData]);

  return (
    <Modal
      open={open}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          แก้ไขการแจ้งเตือน
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          nodeRef={draggleRef}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      onCancel={handleCancel}
      maskClosable={false}
      width={600}
      onOk={handleOk}
      okButtonProps={{ loading: loading }}
    >
      <Card>
        <Form
          ref={formRef}
          variant="filled"
          autoComplete="off"
          {...formItemLayout}
          onFinish={handleFinish}
        >
          <Form.Item
            label="ชื่อแจ้งเตือน"
            name="notiName"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input allowClear placeholder="Enter Notification Name" />
          </Form.Item>

          <Form.Item
            label="โทเค่น"
            name="token"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input allowClear placeholder="Enter Token Line" />
          </Form.Item>

          <Form.Item
            label="ชื่อกลุ่ม"
            name="groupName"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              options={group}
              placeholder="Select Grop Name"
            ></Select>
          </Form.Item>

          <Form.Item
            label="เหตุการณ์"
            name="eventName"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select {...sharedProps} {...selectProps} />
          </Form.Item>

          <Button
            id="edit"
            style={{ display: "none" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </Card>
    </Modal>
  );
}
