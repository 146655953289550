import { BrowserRouter, Routes, Route } from "react-router-dom";
import Playmedia from "./Playmedia";
import Login from "./LoginPage";
import ReportCenter from "./ReportCenter";
import NoPage from "./NoPage";
import "@sweetalert2/theme-dark/";
import RealtimeVDO from "./scgp/RealtimeVDO";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ReportCenter />} />
        <Route path="/login" element={<Login />} />
        <Route path="/noti/play" element={<Playmedia />} />
        <Route path="/play" element={<RealtimeVDO />} />        
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
