import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Card,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import bg from "./images/bg-login.jpg";
import { LoadingButton } from "@mui/lab";
import Apix from "../Apix";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: { fontFamily: "kanit" },
});

function Login() {
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [remember, setRemember] = useState(true);
  const handleLogin = async () => {
    setErrMessage("");
    if (!password || !username) {
      setErrMessage("Please enter your username and password");
      return;
    }
    setLoading(true);
    const loginData = await Apix.login(username, password);
    const { accessToken } = loginData;
    if (!accessToken) {
      setErrMessage(loginData.message);
    } else {
      localStorage.setItem("accessToken", accessToken);
      if (remember) {
        localStorage.setItem("username", username);
      } else {
        localStorage.removeItem("username");
      }
      window.location.href = "/";
    }
    setLoading(false);
  };

  useEffect(() => {
    const rememberData = localStorage.getItem("username");
    if (rememberData) {
      setUsername(rememberData);
    }
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      window.location.href = "/";
    }
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: "100vh",
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundBlendMode: "overlay",
          backgroundColor: "#004faa80",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth="sm">
          <Card sx={{ borderRadius: 8, p: 4, backgroundColor: "#252a4ceb" }}>
            <Stack spacing={3}>
              <Stack spacing={1}>
                <Typography textAlign={"center"} variant="h4">
                  Login
                </Typography>
                <Typography
                  textAlign={"center"}
                  component={"div"}
                  variant="caption"
                >
                  Report Management Control Center
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <TextField
                  label="Username"
                  autoComplete="off"
                  fullWidth
                  variant="filled"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  label="Password"
                  autoComplete="off"
                  fullWidth
                  variant="filled"
                  value={password}
                  type={type}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setType(type === "password" ? "text" : "password")
                          }
                        >
                          {type === "password" ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={remember}
                        onChange={(e) => setRemember(e.target.checked)}
                        defaultChecked
                      />
                    }
                    label="Remeber me"
                  />
                </Box>
                <LoadingButton
                  onClick={handleLogin}
                  size="large"
                  variant="contained"
                  loading={loading}
                >
                  Login Now
                </LoadingButton>
              </Stack>
              {errMessage && (
                <Alert severity="error" variant="filled">
                  <AlertTitle>Error</AlertTitle>
                  {errMessage}
                </Alert>
              )}
            </Stack>
          </Card>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Login;
